import Subscribe from "../components/subscribe/subscribe";
import AdvocacyBanner from "../components/advocacy/banner/banner";
import AdvocacyAim from "../components/advocacy/aim/aim";
import banner from "../assets/images/ong-banner.jpeg";
import data from "../data.json";
import aim1 from "../assets/images/ayodele-caritatif-dons.jpeg";
import aim2 from "../assets/images/ayodele-caritatif-hopital.jpeg";
import aim3 from "../assets/images/ayodele-caritatif-chaise.jpeg";
import bbtresor from "../assets/images/bbtresor.jpeg";
import calavi from "../assets/images/calavi.jpeg";
import ganvie from "../assets/images/ganvie.jpeg";
import giselle from "../assets/images/giselle.jpeg";
import issowe from "../assets/images/issowe.jpeg";
import jeuneshandicap from "../assets/images/jeunes-handicap.jpeg";
import pelagie from "../assets/images/pelagie.jpeg";
import rose from "../assets/images/rose.jpeg";
import zongo from "../assets/images/zongo.jpeg";
import anges from "../assets/images/anges.jpg";




const ongContent = [
    { head: data.advocacy.aim4Head, desc: data.advocacy.aim4Desc, img: bbtresor },
    { head: data.advocacy.aim5Head, desc: data.advocacy.aim5Desc, img: giselle },
    { head: data.advocacy.aim6Head, desc: data.advocacy.aim6Desc, img: rose },
    { head: data.advocacy.aim7Head, desc: data.advocacy.aim7Desc, img: anges },
    { head: data.advocacy.aim8Head, desc: data.advocacy.aim8Desc, img: ganvie },
    { head: data.advocacy.aim9Head, desc: data.advocacy.aim9Desc, img: pelagie },
    { head: data.advocacy.aim10Head, desc: data.advocacy.aim10Desc, img: zongo },
    { head: data.advocacy.aim11Head, desc: data.advocacy.aim11Desc, img: calavi },
    { head: data.advocacy.aim12Head, desc: data.advocacy.aim12Desc, img: issowe },
    { head: data.advocacy.aim13Head, desc: data.advocacy.aim13Desc, img: jeuneshandicap },
]

export default function Advocacy() {
    return(
        <>
            <div className="advocacyBanner" style={{backgroundImage: `url(${banner})`}}>
                <div className="overlay"></div>
                <div className="content">
                    <div className="section">
                        <h1 className="bannerDesc text-center">{data.advocacy.banner}</h1>
                    </div>
                </div>
            </div>


            <div className="section">
                <div className="parentAdvocacyAim">
                    <div className="childAdvocacyAim1">
                        <p className="text-center"><img className="advocacyAimImg" src={aim1} alt="aim"
                                                        style={{objectFit: 'cover', width: '100%', height: '100%'}}/>
                        </p>
                    </div>
                    <div className="childAdvocacyAim2">
                        <h1 className="head">{data.advocacy.aim1Head}</h1>
                        <br/>
                        <p className="desc">
                            {data.advocacy.aim1Desc}
                        </p>
                        <br/>
                        <p>
                            {data.advocacy.aim1Btn && <button className="btn btn-dark">{data.advocacy.aim1Btn}</button>}

                        </p>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="parentAdvocacyAimRev">
                    <div className="childAdvocacyAim1">
                        <h1 className="head">{data.advocacy.aim2Head}</h1>
                        <br/>
                        <p className="desc">
                            {data.advocacy.aim2Desc}
                            <br/>
                        </p>
                        <br/>
                        <p>
                            {data.advocacy.aim2Btn && <button className="btn btn-dark">{data.advocacy.aim2Btn}</button>}
                        </p>
                    </div>
                    <div className="childAdvocacyAim2">
                        <p className="text-center"><img className="advocacyAimImg" src={aim2} alt="aim"
                                                        style={{objectFit: 'cover', width: '100%', height: '100%'}}/>
                        </p>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="parentAdvocacyAim">
                    <div className="childAdvocacyAim1">
                        <p className="text-center"><img className="advocacyAimImg" src={aim3} alt="aim"
                                                        style={{objectFit: 'cover', width: '100%', height: '100%'}}/>
                        </p>
                    </div>
                    <div className="childAdvocacyAim2">
                        <h1 className="head">{data.advocacy.aim3Head}</h1>
                        <br/>
                        <p className="desc">
                            {data.advocacy.aim3Desc}
                        </p>
                        <br/>
                        <p>
                            {data.advocacy.aim3Btn && <button className="btn btn-dark">{data.advocacy.aim3Btn}</button>}

                        </p>
                    </div>
                </div>
            </div>





            {ongContent.map((aim, index) => (
                <div className="section" key={index}>
                    <div className="parentAdvocacyAim">
                        <div className="childAdvocacyAim1">
                            <p className="text-center"><img className="advocacyAimImg" src={aim.img} alt="aim"
                                                            style={{objectFit: 'cover', width: '100%', height: '100%'}}/>
                            </p>
                        </div>
                        <div className="childAdvocacyAim2">
                            <h1 className="head">{aim.head}</h1>
                            <br/>
                            <p className="desc">
                                {aim.desc}
                            </p>
                            <br/>
                            <p>
                                {aim.btn && <button className="btn btn-dark">{aim.btn}</button>}
                            </p>
                        </div>
                    </div>
                </div>
            ))}

        </>
    )
}
