import HomeBanner from "../components/home/banner/banner";
import homeBanner from '../assets/video/homeBanner.mp4'
import homeHall from '../assets/video/homeHall.mp4'
import homeUpcomming from '../assets/video/homeUpcomming.mp4'
import homeFeature from '../assets/video/homeFeature.mp4'
import homeSingle from '../assets/video/homeSingle.mp4'
import Ayodele1 from '../assets/video/Ayodele1.mp4'
import Ayodele2 from '../assets/video/videobg1.mp4'
import Ayodele3 from '../assets/video/videobg2.mp4'
import Ayodele4 from '../assets/video/Ayodele4.mp4'
import Ayodele5 from '../assets/video/videobg3.mp4'
import Ayodele6 from '../assets/video/Ayodele6.mp4'
import Ayodele7 from '../assets/video/Ayodele7.mp4'
import Subscribe from "../components/subscribe/subscribe";
import Footer from "../common/Footer/Footer";
import HomeAlbum from "../components/home/album/album";
import data from '../data.json'

export default function Home() {
    return (
        <>
            <HomeBanner
                video={Ayodele5}
                head={data.home.ONG.head}
                desc={data.home.ONG.desc}
                btn={data.home.ONG.btn}
                link={data.home.ONG.link}
                textColor={data.home.ONG.textColor}
            />
            <HomeBanner
                video={Ayodele2}
                head=""
                desc={data.home.intro.desc}
                btn=""
            />
            <HomeBanner
                video={Ayodele3}
                head={data.home.biographie.head}
                desc={data.home.biographie.desc}
                btn={data.home.biographie.btn}
                link={data.home.biographie.link}
                textColor={data.home.biographie.textColor}
            />
            {/*<HomeAlbum />
            <HomeBanner
                video={Ayodele4}
                head={data.home.actualites.head}
                desc={data.home.actualites.desc}
                btn={data.home.actualites.btn}
                link={data.home.actualites.link}
                textColor={data.home.actualites.textColor}
            />*/}
            {/*<HomeBanner
                video={Ayodele1}
                head=""
                desc={data.home.homeSingleDesc}
                btn={data.home.homeSingleBtn}
            />
            <Subscribe/>*/}
        </>
    );
}
