import {FaFacebook, FaInstagram, FaMusic, FaSpotify, FaTiktok, FaTwitter, FaYoutube} from "react-icons/fa";
import "./Footer.css";
import {Link} from "react-router-dom";
import data from '../../data.json'
import logo from "../../assets/images/logo-noir.png";
import {FaX} from "react-icons/fa6";


const Footer = () => {
    return (
        <div className="footer section">
            {/*<div>
          <img
          src={logo}
          alt="img"
          width={280}
        />
        <div style={{ display: "flex", alignItems: "center", gap: 5, width: "100%" }}>
          <input
            placeholder="Search"
            className="footer_input"
          />
          <button className="footer_btn">Contact us</button>
        </div>
      </div>*/}
            <div className="social_icons">
                {/*<Link className="link" to={data.footer.instagram}><FaInstagram size={20}/></Link>*/}
                <Link className="link" to={data.footer.instagram}><FaInstagram size={20}/></Link>
                <Link className="link" to={data.footer.youtube}><FaYoutube size={20}/></Link>
                <Link className="link" to={data.footer.facebook}><FaFacebook size={20}/></Link>
                <Link className="link" to={data.footer.tiktok}><FaTiktok size={20}/></Link>
                <Link className="link" to={data.footer.x}><FaX size={20}/></Link>
                {/*<Link className="link" to={data.footer.twitter}><FaTwitter size={20}/></Link>*/}
                {/*<Link className="link" to={data.footer.music}><FaMusic size={20}/></Link>*/}
                {/*<Link className="link" to={data.footer.spotify}><FaSpotify size={20}/></Link>*/}
            </div>
        </div>
    );
};

export default Footer;
