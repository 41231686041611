import './banner.css'

export default function HomeBanner(props) {
    return(
        <>
            <div>
                <div className="video-background">
                    <video autoPlay muted loop className="video">
                        <source src={props.video} type="video/mp4" />
                    </video>
                    <div className="content" style={{ color: props.textColor }}>
                        <p className="bannerHead">{props.head}</p>
                        <p className="bannerDesc">{props.desc}</p>
                        <p>{props.btn ? <button className="btn btn-light bannerBtn"
                                                onClick={() => window.location.href = props.link}>{props.btn.toUpperCase()}</button> : ""}</p></div>
                </div>
            </div>
        </>
    )
}
