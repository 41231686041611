import Subscribe from "../components/subscribe/subscribe";
import AdvocacyBanner from "../components/advocacy/banner/banner";
import AdvocacyAim from "../components/advocacy/aim/aim";
import banner from "../assets/images/ong-banner.jpeg";
import data from "../data.json";
import aim1 from "../assets/images/ayodele-caritatif-dons.jpeg";
import aim2 from "../assets/images/ayodele-caritatif-hopital.jpeg";
import aim3 from "../assets/images/ayodele-caritatif-chaise.jpeg";
import bbtresor from "../assets/images/bbtresor.jpeg";
import calavi from "../assets/images/calavi.jpeg";
import ganvie from "../assets/images/ganvie.jpeg";
import giselle from "../assets/images/giselle.jpeg";
import issowe from "../assets/images/issowe.jpeg";
import jeuneshandicap from "../assets/images/jeunes-handicap.jpeg";
import pelagie from "../assets/images/pelagie.jpeg";
import rose from "../assets/images/rose.jpeg";
import zongo from "../assets/images/zongo.jpeg";
import anges from "../assets/images/anges.jpg";
import {FaTiktok, FaWhatsapp} from "react-icons/fa";
import aim4 from "../assets/images/ayodele-biographie5.jpg";





export default function Contact() {
    return(
        <>
            <div className="section">
                <div className="parentAdvocacyAim">
                    <div className="childAdvocacyAim1">
                        <p className="text-center"><img className="advocacyAimImg" src={aim4} alt="aim"
                                                        style={{objectFit: 'cover', width: '100%', height: '100%'}}/>
                        </p>
                    </div>
                    <div className="childAdvocacyAim2">
                        <h1 className="head">
                            Contact
                        </h1>
                        <br/>
                        <p className="desc">
                            <a href="https://wa.me/+22995501579" className="btn btn-dark">
                                <FaWhatsapp size={20}/> Whatsapp : +229 95 50 15 79
                            </a>
                        </p>
                        <br/>
                        <p>
                            {data.advocacy.aim1Btn && <button className="btn btn-dark">{data.advocacy.aim1Btn}</button>}

                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
