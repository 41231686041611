import Subscribe from "../components/subscribe/subscribe";
import AdvocacyBanner from "../components/advocacy/banner/banner";
import AdvocacyAim from "../components/advocacy/aim/aim";
import banner from "../assets/images/ayodele-biographie-4.jpg";
import data from "../data.json";
import aim1 from "../assets/images/ayodele-biographie-1.jpg";
import aim2 from "../assets/images/ayodele-biographie-2.jpg";
import aim3 from "../assets/images/ayodele-biographie-3.jpg";
import aim4 from "../assets/images/ayodele-biographie5.jpg";

export default function Biographie() {
    return(
        <>
            <div className="advocacyBanner" style={{backgroundImage: `url(${banner})`}}>
                <div className="overlay"></div>
                <div className="content">
                    <div className="section">
                        <h1 className="bannerDesc text-center">{data.biographie.banner}</h1>
                    </div>
                </div>
            </div>


            <div className="section">
                <div className="parentAdvocacyAim">
                    <div className="childAdvocacyAim1">
                        <p className="text-center"><img className="advocacyAimImg" src={aim1} alt="aim"
                                                        style={{objectFit: 'cover', width: '100%', height: '100%'}}/>
                        </p>
                    </div>
                    <div className="childAdvocacyAim2">
                        <h1 className="head">{data.biographie.aim1Head}</h1>
                        <br/>
                        <p className="desc">
                            {data.biographie.aim1Desc}
                            <br/>
                            <br/>
                            {data.biographie['aim1-1Desc']}
                        </p>
                        <br/>
                        <p>
                            {data.biographie.aim1Btn &&
                                <button className="btn btn-dark">{data.biographie.aim1Btn}</button>}

                        </p>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="parentAdvocacyAimRev">
                    <div className="childAdvocacyAim1">
                        <h1 className="head">{data.biographie.aim2Head}</h1>
                        <br/>
                        <p className="desc">
                            {data.biographie.aim2Desc}
                            <br/>
                        </p>
                        <br/>
                        <p>
                            {data.biographie.aim2Btn &&
                                <button className="btn btn-dark">{data.biographie.aim2Btn}</button>}
                        </p>
                    </div>
                    <div className="childAdvocacyAim2">
                        <p className="text-center"><img className="advocacyAimImg" src={aim2} alt="aim"
                                                        style={{objectFit: 'cover', width: '100%', height: '100%'}}/>
                        </p>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="parentAdvocacyAim">
                    <div className="childAdvocacyAim1">
                        <p className="text-center"><img className="advocacyAimImg" src={aim3} alt="aim"
                                                        style={{objectFit: 'cover', width: '100%', height: '100%'}}/>
                        </p>
                    </div>
                    <div className="childAdvocacyAim2">
                        <h1 className="head">{data.biographie.aim3Head}</h1>
                        <br/>
                        <p className="desc">
                            {data.biographie.aim3Desc}
                        </p>
                        <br/>
                        <p>
                            {data.biographie.aim3Btn &&
                                <button className="btn btn-dark">{data.biographie.aim3Btn}</button>}

                        </p>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="parentAdvocacyAimRev">
                    <div className="childAdvocacyAim1">
                        <h1 className="head">{data.biographie.aim4Head}</h1>
                        <br/>
                        <p className="desc" dangerouslySetInnerHTML={{__html: data.biographie.aim4Desc}}></p>
                        <br/>
                        <p>
                            {data.biographie.aim2Btn &&
                                <button className="btn btn-dark">{data.biographie.aim2Btn}</button>}
                        </p>
                    </div>
                    <div className="childAdvocacyAim2">
                        <p className="text-center"><img className="advocacyAimImg" src={aim4} alt="aim"
                                                        style={{objectFit: 'cover', width: '100%', height: '100%'}}/>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
