import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./pages/home";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Ong from "./pages/ong";
import Header from "./common/Header/Header";
import Footer from "./common/Footer/Footer";
import Tours from "./pages/tours";
import Career from "./pages/career";
import Biographie from "./pages/biographie";
import Contact from "./pages/contact";

function App() {
  return (
    <>
        <div className="App">
            <BrowserRouter>
                <Header/>
                <Routes>
                    <Route exact path={'/'} element={<Home/>}/>
                    <Route path={'/ong'} element={<Ong/>}/>
                    <Route path={'/biographie'} element={<Biographie/>}/>
                    <Route path={'/contact'} element={<Contact/>}/>
                    {/*
                    <Route path={'/tours'} element={<Tours/>}/>
                    <Route path={'/career'} element={<Career/>}/>*/}
                </Routes>
                <Footer/>
            </BrowserRouter>
        </div>
    </>
  );
}

export default App;
