import "./Header.css";
import { Link } from "react-router-dom";
import { IoChevronDown } from "react-icons/io5";
import { useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { FaBars } from "react-icons/fa";
import data from '../../data.json'
import logo from "../../assets/images/logo-rouge-transparent.png";

const Header = () => {
    const [showSidebar, setShowSidebar] = useState(false);
    const headerLinks = Object.values(data.header);

    return (
        <div className="header">
            <img
                src={logo}
                alt="img"
                width={150}
            />
            <div
                className="hamburger"
                onClick={() => setShowSidebar(true)}
            >
                <FaBars
                    fill="#fff"
                    size={20}
                />
            </div>
            <div
                className="header_links"
                style={{ right: showSidebar ? 0 : -200 }}
            >
                <div
                    className="close_icon"
                    onClick={() => setShowSidebar(false)}
                >
                    <IoMdCloseCircle
                        fill="#fff"
                        size={20}
                    />
                </div>
                {headerLinks.map((menuItem, index) => (
                    <Link key={index} to={`${menuItem.link}`}>{menuItem.text}</Link>
                ))}
            </div>
        </div>
    );
};

const Links = () => {
    const [open, setOpen] = useState(false);
    return (
        <div className="link_wrapper">
            <div
                onClick={() => setOpen((prev) => !prev)}
                style={{ display: "flex", alignItems: "center", gap: 10 }}
            >
                <Link to="#">Project</Link>
                <IoChevronDown
                    stroke="#fff"
                    style={{
                        transition: "0.2s all ease-in-out",
                        transform: `rotate(${open ? 180 : 0}deg)`,
                    }}
                />
            </div>
            {open && (
                <div className="link_children">
                    <Link to="#">Mother Nature</Link>
                    <Link to="#">Remain In Light</Link>
                    <Link to="#">Celia</Link>
                </div>
            )}
        </div>
    );
};

export default Header;
